<template>
  <b-card>
    <b-row>
      <b-col cols="6">Account Setting General</b-col>
      <b-col cols="6" class="text-right">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" class="mr-1" @click="downloadPractice">Full Data Export</b-button>
      </b-col>
    </b-row>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="name"
              placeholder="Name"
              name="name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="email"
              name="email"
              placeholder="Email"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Receive Task Email Notifications?" label-for="task_email">
            <b-form-checkbox id="task_email" v-model="task_email" name="task_email" class="mr-0" switch inline/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="saveAccount()"
          >
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,BFormCheckbox, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import userService from "@core/services/user/useUser";
import practiceService from '@core/services/practice/usePractice'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormCheckbox,
    BLink,
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.userData = userService.getUserData();
    this.id = this.userData.id;
    this.name = this.userData.fullName;
    this.email = this.userData.email;
    this.task_email = this.userData.task_email;
  },
  data() {
    return {
      id: 0,
      name: "",
      email: "",
      task_email: 0,
      userData: {},
    }
  },
  methods: {
    downloadPractice(){
      practiceService.getPracticeDownload({responseType: "blob"}).then(response => {
        let currDate = new Date().valueOf();
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download","Practice" + currDate + ".zip");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      });
    },
    saveAccount(){
      userService.updateUser({id: this.id,name: this.name,email: this.email,task_email: this.task_email}).then(response => {
        if(!response.data){
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Success!`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `You have successfully updated your settings`,
            },
          })
          userService.updateUserData(this.name,this.email,this.task_email);
        }
      });
    },
  },
}
</script>